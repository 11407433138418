@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass'
// app global css in SCSS form
.page_style
  margin: 140px 0px 0px 0px
  padding: 0px 0px 0px 0px
  background-color: #FFFFFF

.constrain
  max-width: 1200px
  margin: 0 auto

.constrain_m
  max-width: 800px
  margin: 0 auto

.constrain_s
  max-width: 500px
  margin: 0 auto

.welcome-header
  // background-color: transparent
  background-image: url("/header_bg2.png")
  background-repeat: no-repeat
  background-size: cover
  color: white
  @media (max-width: $breakpoint-xs-max)
    height: 550px
  @media (min-width: $breakpoint-sm-min)
    height: 700px

.header
  height: 140px
  // background-color: transparent
  background-image: url("/header_bg2.png")
  background-repeat: no-repeat
  background-size: cover
  color: white

.q-page-container-style
  padding: 100px 0px 0px 0px

@font-face
  font-family: nixgon
  src: url(./fonts/NIXGONFONTS_B_2.0.otf)

@font-face
  font-family: NanumSquare
  src: url(./fonts/NanumSquareR.otf)

@font-face
  font-family: NanumSquareBold
  src: url(./fonts/NanumSquareB.otf)

@font-face
  font-family: NanumSquareExtraBold
  src: url(./fonts/NanumSquareEB.otf)

@font-face
  font-family: NanumSquareRound
  src: url(./fonts/NanumSquareRoundOTFR.otf)

@font-face
  font-family: NanumSquareRoundBold
  src: url(./fonts/NanumSquareRoundOTFB.otf)

@font-face
  font-family: NanumSquareRoundExtraBold
  src: url(./fonts/NanumSquareRoundOTFEB.otf)

h1
    font-family: 'NanumSquareExtraBold', 'futura-pt', 'Roboto', 'Helvetica Neue','nixgon', Helvetica, Arial, sans-serif
    @media (max-width: $breakpoint-xs-max)
        font-size:40px
        line-height: 1.5
    @media (min-width: $breakpoint-sm-min)
        font-size:80px
h2
    font-family: 'NanumSquareExtraBold', 'futura-pt', 'Roboto', 'Helvetica Neue','nixgon', Helvetica, Arial, sans-serif
    padding: 0px 0px 0px 0px
    margin: 0px 0px 0px 0px
    @media (max-width: $breakpoint-xs-max)
        font-size:30px
    @media (min-width: $breakpoint-sm-min)
        font-size:47px

h3
    font-family: 'NanumSquareExtraBold', 'futura-pt', 'Roboto', 'Helvetica Neue','nixgon', Helvetica, Arial, sans-serif
    @media (max-width: $breakpoint-xs-max)
        font-size:25px
    @media (min-width: $breakpoint-sm-min)
        font-size:40px
h4
    font-family: 'NanumSquareExtraBold', Helvetica, Arial, sans-serif
    padding: 0px 0px 0px 0px
    margin: 0px 0px 0px 0px
h5
    font-family: 'NanumSquareExtraBold', 'futura-pt', 'Roboto', 'Helvetica Neue','nixgon', Helvetica, Arial, sans-serif
    padding: 0px 0px 0px 0px
    margin: 0px 0px 0px 0px

h6
    font-family: 'NanumSquareExtraBold','futura-pt', 'Roboto', 'Helvetica Neue','nixgon', Helvetica, Arial, sans-serif
    padding: 0px 0px 0px 0px
    margin: 0px 0px 0px 0px
    color: #999

.text-caption
  font-family: 'NanumSquareBold', Helvetica, Arial, sans-serif
  font-size:14px
  color: #666

.text-h5
  font-family: 'NanumSquareExtraBold', Helvetica, Arial, sans-serif

.topmenu_text
  font-family: 'NanumSquareRoundExtraBold', 'futura-pt', 'Roboto', 'Helvetica Neue','nixgon', Helvetica, Arial, sans-serif
  font-size: 16px
